/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, VideoIframe, LandingPageCta, MapInfo, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aid-technology/hearing-aids-vs-hearing-amplifiers/'], ['en', 'https://www.hear.com/resources/hearing-aid-technology/hearing-aids-vs-hearing-amplifiers/'], ['en-US', 'https://www.hear.com/resources/hearing-aid-technology/hearing-aids-vs-hearing-amplifiers/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aids/hearing-aids-vs-hearing-amplifiers/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hearing-aids-or-hearing-amplifiers-which-one-is-right-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-or-hearing-amplifiers-which-one-is-right-for-you",
    "aria-label": "hearing aids or hearing amplifiers which one is right for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids or Hearing Amplifiers: Which One Is Right for You?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Should you get a hearing aid or hearing amplifier? And what’s the difference between the two? Hearing amplifiers (also known as personal sound amplification products) do make sounds louder. But due to a number of limitations, the FDA does not recognize them as medical devices. Audiologists do not recommend hearing amplifiers or any product that is not FDA-regulated to treat hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "Hearing aids"), " are medical devices that treat hearing loss. Licensed professionals must program these devices based on an individual’s prescription or audiogram. The FDA regulates hearing aids to ensure patient safety. Make sure you understand the differences before you spend your hard-earned money on what you believe is a “cheaper” option."), "\n", React.createElement(VideoIframe, {
    title: "HearWell - Hearing Aids vs Hearing Amplifiers",
    width: "500",
    height: "281",
    src: "https://www.youtube.com/embed/QRIiXJ29FWQ?feature=oembed"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-amplifiers-vs-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-amplifiers-vs-hearing-aids",
    "aria-label": "hearing amplifiers vs hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing amplifiers vs. hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are not cheap. So it’s understandable why people consider hearing amplifiers. Although amplifiers are advertised as a one-size-fits-all solution to hearing loss, they cannot support that claim. Your hearing loss is as unique as your fingerprint. So you need a solution that meets your exact hearing needs. Only medical-grade hearing aids prescribed by a licensed professional can really treat hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While hearing amplifiers may seem like a cheaper option, they won’t fix your hearing loss. In fact, it might even make things worse."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-amplifiers-do-not-clarify-speech",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-amplifiers-do-not-clarify-speech",
    "aria-label": "hearing amplifiers do not clarify speech permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing amplifiers do not clarify speech"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An amplifier will just make all the sounds in an environment louder. It won’t make them clearer. Understanding speech requires a balance between specific frequencies. Hearing amplifiers also have the following limitations:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Can’t separate speech from noise"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are not customizable to your hearing loss"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "No feedback cancellation or wind noise reduction"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Not worth the money"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "With all these limitations, most audiologists don’t recommend hearing amplifiers to their patients."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-dangers-of-hearing-amplifiers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-dangers-of-hearing-amplifiers",
    "aria-label": "the dangers of hearing amplifiers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The dangers of hearing amplifiers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When used to treat hearing loss, hearing amplifiers can do more harm than good. Hearing amplifiers are designed for people with normal hearing. If you’re hearing is okay, you might benefit from using a hearing amplifier in challenging settings. But for someone with hearing loss, simply amplifying all sounds can actually cause further damage."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-hearing-amplifiers-actually-good-for",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-hearing-amplifiers-actually-good-for",
    "aria-label": "what are hearing amplifiers actually good for permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are hearing amplifiers actually good for?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing amplifiers are good for anyone who just wants to hear things louder. For example, people with normal hearing might sparingly use an amplifier for activities like birdwatching. You can think of amplifiers as binoculars for your ears: they zoom in on what you can hear already so you can appreciate it a bit more."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "do-you-need-a-hearing-aid-or-hearing-amplifier",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#do-you-need-a-hearing-aid-or-hearing-amplifier",
    "aria-label": "do you need a hearing aid or hearing amplifier permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Do you need a hearing aid or hearing amplifier?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best way to answer this question is by getting your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing tested"), ". If you have trouble understanding speech or you listen to the TV too loud, then it might be time to visit your local hearing specialist. They will be able to tell you if you’re experiencing hearing loss and would recommend hearing aids. Don’t assume your hearing isn’t that bad. Seek the help of a hearing care professional so that you make the right decision."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-expert-advice-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-expert-advice-today",
    "aria-label": "get expert advice today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get expert advice today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Every day we advise thousands of people who are having trouble with their hearing. We are a reputable company with the knowledge and experience to point you in the right direction. In addition, we have access to over 2,000 of the top hearing care providers across the US. If you haven’t reserved a 45-day trial yet, you can do so now. Just click the button below, answer a few simple questions, and we’ll take care of the rest."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
